import React from 'react'
import PageTitle from '../../components/PageTitle'
import { useHistory, useParams } from 'react-router-dom'

import CardTelemetry from '../../components/CardTelemetry'
import { toast } from 'react-toastify'

// import { Container } from './styles';

const socket: any = null

const View: React.FC = () => {
  const [telemetry, setTelemetry] = React.useState<any[]>([])

  const imageRef = React.useRef<any>()
  const history = useHistory()
  const params: any = useParams()
  const id = params.id

  const onBeforeUnload = (e: any) => {
    socket.emit('control', 'stop')
    socket.emit('deallocate')
    console.log('desconectado')
    toast.warn('Você foi desalocado do robô!')
  }

  React.useEffect(() => {
    socket.on('live', (message) => {
      var bytes = new Uint8Array(message)
      var binary = ''
      var len = bytes.byteLength
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      if (imageRef.current) {
        imageRef.current.src = 'data:image/jpg;base64,' + window.btoa(binary)
      }
    })

    socket.on('telemetry', (message: any[]) => {
      // Signal RSSI, -30 dBm to - 90 dBm, convert to percent
      message = message.map((item) => {
        item.signal_percent = Math.round(((item.signal + 90) * 100) / 60)
        return item
      })

      console.log(message)
      setTelemetry(message)
    })

    return () => {
      socket.off('live')
      socket.off('telemetry')
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    const unregister = history.listen(onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
      unregister()
    }
  }, [history])

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <PageTitle
              title={'Visualizado - ' + id}
              path={[
                {
                  name: 'Home',
                  path: '/',
                },
              ]}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <CardTelemetry />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex justify-content-center card card-body p-0">
              <img className="w-100 img-fluid rounded" ref={imageRef} alt="Live" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default View
