import React from 'react'
import PageTitle from '../../components/PageTitle'
import { SessionContext } from '../../context/SessionContext'
import PersonalInfo from './PersonalInfo'
import Password from './Password'

const Profile: React.FC = () => {
  const { sessionUser, setSessionUser } = React.useContext(SessionContext)

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <PageTitle
              title={'Minha conta'}
              path={[
                {
                  name: 'Home',
                  path: '/',
                },
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {/* <PersonalInfo sessionUser={sessionUser!} setSessionUser={setSessionUser} /> */}
                <Password />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
