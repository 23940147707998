import React from 'react'
import ContainerAuth from '../../components/ContainerAuth'
import Logo from '../../assets/images/logo-light.png'
import { Link, useHistory } from 'react-router-dom'
import { SessionContext } from '../../context/SessionContext'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Eye, EyeSlash } from 'iconsax-react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { AuthService } from '../../api/AuthService'

type Inputs = {
  name: string
  email: string
  password: string
}

const Register: React.FC = () => {
  const [passwordDisplay, setPasswordDisplay] = React.useState(false)
  const { saveSession, setSessionUser } = React.useContext(SessionContext)
  const history = useHistory()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    AuthService.register(data)
      .then((resp) => {
        saveSession(resp.data.token)
        setSessionUser(resp.data.user)
        history.replace('/home')
      })
      .catch((err) => {
        if (err.response.status === 422) {
          err.response.data.errors.map((error: any) => {
            setError(error.param, { type: 'manual', message: error.msg })
          })
        } else {
          toast.error(err.response.data.message)
        }
      })
  }

  return (
    <ContainerAuth>
      <div className="row justify-content-center">
        <div className="col-xxl-4 col-lg-5">
          <div className="card">
            <div className="card-header text-center bg-primary">
              <span>
                <img src={Logo} alt="logo" height={80} />
              </span>
            </div>
            <div className="card-body p-4">
              <div className="text-center w-75 m-auto">
                <h4 className="text-dark-50 text-center mt-0 fw-bold">Registro</h4>
                <p className="text-muted mb-4">Obtenha acesso ao nosso painel de controle</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control type="text" placeholder="Seu nome" isInvalid={!!errors.name} {...register('name', { required: true })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="nome@gmail.com" isInvalid={!!errors.email} {...register('email', { required: true })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Senha</Form.Label>
                  <InputGroup>
                    <Form.Control type={passwordDisplay ? 'text' : 'password'} placeholder="Sua senha" isInvalid={!!errors.password} {...register('password', { required: true })} />
                    <Button variant="outline-secondary" onClick={() => setPasswordDisplay(!passwordDisplay)}>
                      {passwordDisplay ? <EyeSlash size="16" /> : <Eye size="16" />}
                    </Button>
                    <Form.Control.Feedback type="invalid">{errors.password && errors.password.message}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3" controlId="checkbox-signup">
                  <Form.Check type="checkbox" label={<span className="w-100 p-1">I accept Terms and Conditions</span>} />
                </Form.Group>
                <div className="mb-3 text-center">
                  <Button className="btn btn-primary" type="submit">
                    Sign Up
                  </Button>
                </div>
              </form>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 text-center">
              <p className="text-muted">
                Already have account?
                <Link to="/login" className="text-muted ms-1">
                  <b>Log In</b>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ContainerAuth>
  )
}

export default Register
