import React from 'react'
import { Eye, EyeSlash } from 'iconsax-react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'

import Logo from '../../assets/images/logo-light.png'
import './styles.scss'

import { SessionContext } from '../../context/SessionContext'
import { Button, Form, InputGroup } from 'react-bootstrap'
import ContainerAuth from '../../components/ContainerAuth'
import { AuthService } from '../../api/AuthService'
import { AxiosError } from 'axios'

type Inputs = {
  email: string
  password: string
}

const Login: React.FC = () => {
  const [passwordDisplay, setPasswordDisplay] = React.useState(false)
  const { saveSession, setSessionUser } = React.useContext(SessionContext)
  const history = useHistory()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const loginResponse = await AuthService.login(data)
      if (loginResponse.status === 200) {
        saveSession(loginResponse.data.token)
      }

      const checkResponse = await AuthService.check()
      if (checkResponse.status === 200) {
        setSessionUser(checkResponse.data)
        history.replace('/home')
      }
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        if (err.response) {
          setError('email', { message: err.response.data.message })
          setError('password', { message: err.response.data.message })
        } else {
          toast.error('Erro ao realizar login')
        }
      }
    }
  }

  return (
    <ContainerAuth>
      <div className="row justify-content-center">
        <div className="col-xxl-4 col-lg-5">
          <div className="card">
            <div className="card-header text-center bg-primary">
              <span>
                <img src={Logo} alt="logo" height={80} />
              </span>
            </div>
            <div className="card-body p-4">
              <div className="text-left">
                <h4 className="text-dark-50 pb-0 fw-bold">Login</h4>
                <p className="text-muted mb-4">Entre com seu email e senha para acessar o sistema.</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="nome@gmail.com" isInvalid={!!errors.email} {...register('email', { required: true })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Senha</Form.Label>
                  <InputGroup>
                    <Form.Control type={passwordDisplay ? 'text' : 'password'} placeholder="Sua senha" isInvalid={!!errors.password} {...register('password', { required: true })} />
                    <Button variant="outline-secondary" onClick={() => setPasswordDisplay(!passwordDisplay)}>
                      {passwordDisplay ? <EyeSlash size="16" /> : <Eye size="16" />}
                    </Button>
                    <Form.Control.Feedback type="invalid">{errors.password && errors.password.message}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className="mb-3 mb-0 text-center">
                  <Button type="submit" variant="primary" className="w-100">
                    Entrar
                  </Button>
                </div>
              </form>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 text-center">
              <p className="text-muted">
                Não tem conta ?{' '}
                <Link to="/register" className="text-muted ms-1">
                  <b>Registre-se</b>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ContainerAuth>
  )
}

export default Login
