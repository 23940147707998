import React from 'react'
import { IUser } from '../types/Users'
import { api, socket } from '../api'
import { AuthService } from '../api/AuthService'

export type IUserState = 'LOADING' | 'AUTHENTICATED' | 'UNAUTHENTICATED'

interface ISessionContext {
  saveSession: (token: string) => void
  setSessionUser: (user: IUser) => void
  removeSession: () => void
  getSession: () => string | null
  state: IUserState
  sessionUser?: IUser
}

export const SessionContext = React.createContext({} as ISessionContext)

interface IProps {
  children: React.ReactNode
}

const SessionProvider: React.FC<IProps> = ({ children }) => {
  const [state, setState] = React.useState<IUserState>('LOADING')
  const [sessionUser, setSessionUser] = React.useState<IUser | undefined>(undefined)

  React.useEffect(() => {
    const token = localStorage.getItem('token')
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    AuthService.check()
      .then((res) => {
        if (res.status === 200) {
          setSessionUser(res.data)
          setState('AUTHENTICATED')
          socket.io.opts.query = { token }
          socket.connect()
        }
      })
      .catch(() => {
        setState('UNAUTHENTICATED')
      })
  }, [])

  const saveSession = (token: string) => {
    localStorage.setItem('token', token)
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    setState('AUTHENTICATED')
    socket.io.opts.query = { token }
    socket.connect()
  }

  const removeSession = () => {
    localStorage.removeItem('token')
    setState('UNAUTHENTICATED')
    socket.disconnect()
  }

  const getSession = () => {
    return localStorage.getItem('token')
  }

  return <SessionContext.Provider value={{ saveSession, setSessionUser, removeSession, getSession, state, sessionUser }}>{children}</SessionContext.Provider>
}

export default SessionProvider
