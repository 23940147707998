import axios from 'axios'
import { io } from 'socket.io-client'

const URL = process.env.REACT_APP_API_URL as string

export const api = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})

export const socket = io(URL, {
  transports: ['websocket'],
  upgrade: false,
  autoConnect: false,
  query: {
    token: localStorage.getItem('token'),
  },
})
