import Login from '../pages/Login'
import { Switch, Route, Redirect } from 'react-router-dom'
import Register from '../pages/Register'

const Outdoor: React.FC = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/*" exact>
        <Redirect to="/login" />
      </Route>
    </Switch>
  )
}

export default Outdoor
