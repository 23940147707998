import { useForm, SubmitHandler } from 'react-hook-form'
import { Security } from 'iconsax-react'
import React from 'react'
import { toast } from 'react-toastify'
import { UserService } from '../../api/UserService'

type Inputs = {
  old_password: string
  new_password: string
  new_password_repeater: string
}

const Password: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (data.new_password !== data.new_password_repeater) {
      return setError('new_password', {
        type: 'manual',
        message: 'As senhas não conferem',
      })
    }

    UserService.updatePassword(data.new_password, data.old_password)
      .then(() => {
        toast.success('Senha alterada com sucesso!')
      })
      .catch((err) => {
        toast.error('Não foi possível alterar a senha')
      })
  }

  return (
    <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
      <h5 className="mb-3 text-uppercase bg-light p-2 d-flex justify-content-start align-items-center">
        <Security className="me-2" size="20" /> Segurança
      </h5>
      <div className="row">
        <div className="col-12">
          <div className="mb-3">
            <label htmlFor="userpassword" className="form-label">
              Antiga Senha
            </label>
            <input type="password" className="form-control" placeholder="Antinga senha" {...register('old_password')} />
            {errors.old_password && <span className="text-danger">{errors.old_password.message}</span>}
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="userpassword" className="form-label">
              Nova senha
            </label>
            <input type="password" className="form-control" placeholder="Nova senha" {...register('new_password')} />
            {errors.new_password && <span className="text-danger">{errors.new_password.message}</span>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="userpasswordrepeter" className="form-label">
              Repita a senha
            </label>
            <input type="password" className="form-control" placeholder="Repita a nova senha" {...register('new_password_repeater')} />
            {errors.new_password_repeater && <span className="text-danger">{errors.new_password_repeater.message}</span>}
          </div>
        </div>
      </div>
      <div className="text-end">
        <button type="submit" className="btn btn-success mt-2">
          Salvar
        </button>
      </div>
    </form>
  )
}

export default Password
