import React from 'react'
import Lottie from 'lottie-react'
import loadLottie from '../../assets/lottie/load_lottie.json'

const LoadPage: React.FC = () => {
    return (
        <div className="h-100 vh-100 d-flex justify-content-center align-items-center">
            <Lottie animationData={loadLottie} style={{ height: 100 }} />;
        </div>
    )
}

export default LoadPage
