import React from 'react'
import { Form, Button, Modal } from 'react-bootstrap'

interface IProps {
  show: boolean
  onClose: () => void
}

const ModalProject: React.FC<IProps> = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header className="text-bg-primary border-0">
        <Modal.Title>Novo Projeto</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form id="form-create-project">
          <Form.Group className="mb-3">
            <Form.Label>Organização</Form.Label>
            <Form.Select>
              <option hidden>Selecione uma organização</option>
              <option>Org 1</option>
              <option>Org 2</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nome</Form.Label>
            <Form.Control type="text" placeholder="Loja de roupa" />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer className="d-flex flex-row justify-content-between">
        <Button onClick={onClose} type="button" variant="outline-primary">
          Cancelar
        </Button>
        <Button onClick={onClose} type="submit" variant="primary" form="form-create-project">
          Criar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalProject
