import React from 'react'
import { socket } from '../api'

interface ISocketContext {
  isConnected: boolean
  fooEvents: any[]
}

interface IProps {
  children: React.ReactNode
}

export const SocketContext = React.createContext({} as ISocketContext)

export const SocketProvider: React.FC<IProps> = ({ children }) => {
  const [isConnected, setIsConnected] = React.useState(socket.connected)
  const [fooEvents, setFooEvents] = React.useState<any>([])
  React.useEffect(() => {
    function onConnect() {
      socket.io.opts.query = { token: localStorage.getItem('token') }
      setIsConnected(true)
    }

    function onDisconnect() {
      setIsConnected(false)
    }

    function onFooEvent(value: string) {
      setFooEvents((previous: any) => [...previous, value])
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on('foo', onFooEvent)

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
      socket.off('foo', onFooEvent)
    }
  }, [])

  return <SocketContext.Provider value={{ isConnected, fooEvents }}>{children}</SocketContext.Provider>
}
