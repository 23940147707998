import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Navbar from '../components/Navbar'
import Home from '../pages/Home'
import { SocketProvider } from '../context/SocketContext'
import Profile from '../pages/Profile'
import View from '../pages/View'
import { SessionContext } from '../context/SessionContext'
import AllocatedAdmin from '../pages/AllocatedAdmin'

const Indoor: React.FC = () => {
  const { sessionUser } = React.useContext(SessionContext)
  return (
    <SocketProvider>
      <div className="wrapper">
        <Navbar />
        <div className="content-page">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" exact component={Profile} />
            {/* <Route path="/control/:id" component={Control} /> */}
            <Route path="/view/:id" component={View} />

            {/* {sessionUser?.role === 'ADMIN' && <Route path="/admin/allocated" component={AllocatedAdmin} />} */}

            <Route path="/*" exact>
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </div>
    </SocketProvider>
  )
}

export default Indoor
