import { api } from '.'
import { IAuthLogin, IAuthRegister } from '../types/Auth'
import { IUser } from '../types/Users'

type ILogin = {
  user: IUser
  token: string
}

export const AuthService = {
  login: async (credentials: IAuthLogin) => api.post<ILogin>('/auth/login', credentials),
  check: async () => api.get<IUser>('/users/me'),
  register: async (credentials: IAuthRegister) => api.post<ILogin>('/auth/register', credentials),
}
