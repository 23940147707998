import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import Avatar from 'boring-avatars'
import { Badge } from 'react-bootstrap'

import Logo from '../../assets/images/logo.png'
import { SocketContext } from '../../context/SocketContext'
import { SessionContext } from '../../context/SessionContext'
import { socket } from '../../api'
import './styles.scss'

const Navbar: React.FC = () => {
  const history = useHistory()

  const [latency, setLatency] = React.useState<number>(0)
  const { isConnected } = useContext(SocketContext)
  const { removeSession, sessionUser } = useContext(SessionContext)

  React.useEffect(() => {
    // Ping every 5 seconds
    const data = setInterval(() => {
      const start = Date.now()
      socket.emit('ping')

      const timeout = setTimeout(() => {
        setLatency(-1)
      }, 850)

      socket.once('pong', () => {
        clearTimeout(timeout)
        const latency = Date.now() - start
        setLatency(latency)
      })
    }, 1000)

    return () => {
      clearInterval(data)
    }
  }, [])

  const handlerLogou = () => {
    removeSession()
  }

  const handlerToProfile = () => {
    history.push('/profile')
  }

  const handlerToAdmin = () => {
    history.push('/admin/allocated')
  }

  return (
    <div className="navbar-custom">
      <div className="topbar container-fluid">
        <div className="d-flex align-items-center gap-lg-2 gap-1">
          <div className="logo-topbar">
            <Link to="/" className="logo-light">
              <img src={Logo} alt="logo" />
              <span className="logo-txt">Foundation</span>
            </Link>
          </div>
          {isConnected ? <Badge bg="success">Conectado</Badge> : <Badge bg="danger">Desconectado</Badge>}
          {latency > 0 && <Badge bg="primary">{latency}ms</Badge>}
          {latency === -1 && <Badge bg="dark">Timeout</Badge>}
        </div>
        <ul className="topbar-menu d-flex align-items-center gap-3">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="nav-link dropdown-toggle arrow-none nav-user px-2">
              <span className="account-user-avatar">
                <Avatar variant="beam" size={36} name={sessionUser?.name} />
              </span>
              <span className="d-lg-flex flex-column gap-1 d-none align-items-start">
                <h5 className="my-0">{sessionUser?.name}</h5>
                {/* <h6 className="my-0 fw-normal">{sessionUser?.role}</h6> */}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
              <Dropdown.Item as="button" onClick={handlerToProfile}>
                Minha conta
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">Sobre</Dropdown.Item>
              {/* {sessionUser?.role === 'ADMIN' && (
                <Dropdown.Item as="button" className="text-danger" onClick={handlerToAdmin}>
                  Painel de admin
                </Dropdown.Item>
              )} */}
              <Dropdown.Divider />
              <Dropdown.Item as="button" onClick={handlerLogou}>
                Sair
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
